import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const sectionWrapper = 'w-full mb-12'
const stateWrapper = 'flex items-center font-body mb-4 border-b border-red-100'
const storeWrapper = 'flex flex-col items-center'
const Container = 'w-full mb-2'
const innerDiv2 = 'bg-white flex flex-col rounded shadow-lg my-3 p-4 overflow-hidden'
const innerDiv3 = 'w-full'
const imageDiv = 'w-6/12 mb-6 md:mr-6 mx-auto'
const storeName = 'text-3xl text-red-900 leading-tight'
const salesRepName = 'text-xl lg:text-2xl font-semibold mb-2'
const salesRepAddress = 'font-medium text-gray-800'
const salesRepData = 'text-gray-700'
const salesTerritories = 'font-xs font-semibold text-gray-800 mt-6'
const territoryNames = 'font-display text-xl text-red-900'

const SalesRepCard = ({ repCompName, imgData, imgAlt, repName, repAddress, repPhone, repEmailFirst, repEmailSecond, repWebsite, repTerritories }) => {
  return (
    <div className={sectionWrapper}>
      <div className={stateWrapper}>
        <h2 className={storeName}>{repCompName ? repCompName : "Sales Rep Company"}</h2>
      </div>

      <div className={storeWrapper}>
        <div className={Container}>
          <div className={innerDiv2}>
            <div className='flex flex-col md:flex-row items-start'>
              {imgData
                ?
                <div className={imageDiv}>
                  <GatsbyImage
                    image={imgData}
                    width={500}
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt={imgAlt ? imgAlt : ""}
                    className='w-full rounded shadow-lg'
                  />
                </div>
                :
                null
              }

              <div>
                {repName
                  ?
                  <p className={salesRepName}>{repName}</p>
                  :
                  null
                }
                {repAddress
                  ?
                  <p className={salesRepAddress}>{repAddress}</p>
                  :
                  null
                }
                <ul>
                  {repPhone
                    ?
                    <li className={salesRepData}>{repPhone}</li>
                    :
                    null
                  }
                  {repEmailFirst
                    ?
                    <li className={salesRepData}>{repEmailFirst}</li>
                    :
                    null
                  }
                  {repEmailSecond
                    ?
                    <li className={salesRepData}>{repEmailSecond}</li>
                    :
                    null
                  }
                  {repWebsite
                    ?
                    <li className={salesRepData}>{repWebsite}</li>
                    :
                    null
                  }
                </ul>
              </div>
            </div>

            {repTerritories
              ?
              <div className={innerDiv3}>
                <p className={salesTerritories}>Territories</p>
                <p className={territoryNames}>{repTerritories}</p>
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const SalesRepSectionNew = () => {
  const data = useStaticQuery(graphql`
    query SalesRepSectionNewQuery {
      prismicSalesRepresentativesPage {
        data {
          page_title
          sales_representatives {
            sales_rep_photo {
              gatsbyImageData(width: 500)
              alt
            }
            sales_rep_company_name
            sales_rep_name
            sales_rep_address
            sales_rep_phone
            first_sales_rep_email
            second_sales_rep_email
            sales_rep_website
            sales_rep_territories
          }
        }
      }
    }
  `)

  const doc = data.prismicSalesRepresentativesPage.data
  const reps = doc.sales_representatives

  return (
    <div className='bg-white py-12'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto'>
        <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>{doc.page_title}</h1>
        <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>Please contact your local rep for the best recommendations on local distributors.</p>
      </div>

      <div className='w-11/12 md:w-8/12 lg:w-6/12 xl:w-5/12 xl:max-w-screen-sm mx-auto my-16'>
        {reps.map(item => (
          <SalesRepCard
            repCompName={item.sales_rep_company_name ? item.sales_rep_company_name : null}
            imgData={item.sales_rep_photo ? getImage(item.sales_rep_photo) : null}
            imgAlt={item.sales_rep_photo.alt ? item.sales_rep_photo.alt : null}
            repName={item.sales_rep_name ? item.sales_rep_name : null}
            repAddress={item.sales_rep_address ? item.sales_rep_address : null}
            repPhone={item.sales_rep_phone ? item.sales_rep_phone : null}
            repEmailFirst={item.first_sales_rep_email ? item.first_sales_rep_email : null}
            repEmailSecond={item.second_sales_rep_email ? item.second_sales_rep_email : null}
            repWebsite={item.sales_rep_website ? item.sales_rep_website : null}
            repTerritories={item.sales_rep_territories ? item.sales_rep_territories : null}
          />
        ))}
      </div>
    </div>
  )
}

export default SalesRepSectionNew